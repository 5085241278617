import React, { useEffect } from 'react'

import './assets/styles.css'

const TARGET_SELECTOR = '.landing-section-reveal'
const CLASS_SUPPORTED = 'landing-section-reveal--supported'
const CLASS_ACTIVATED = 'landing-section-reveal--activated'

const OBSERVER_OPTIONS = {
  threshold: 0,
}

const enableSectionReveal = () => {
  if (!('IntersectionObserver' in window)) {
    return
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry, index) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add(CLASS_ACTIVATED)
        }, index * 100)
      }
    })
  }, OBSERVER_OPTIONS)

  const targets: Element[] = [].slice.call(
    document.querySelectorAll(TARGET_SELECTOR)
  )

  for (const target of targets) {
    target.classList.add(CLASS_SUPPORTED)
    observer.observe(target)
  }
}

const SectionReveal: React.FC = () => {
  useEffect(() => enableSectionReveal(), [])

  return null
}

export default SectionReveal
