import React from 'react'
import { PageProps } from 'gatsby'
import Head from 'src/components/Head/Head'
import Layout from 'src/components/Layout/Layout'
import LearnLanguage from 'src/sections/LearnLanguage/LearnLanguage'
import { LanguageCode, PageContext } from 'src/types'

const LearnLanguagePage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  return (
    <Layout pageContext={pageContext} hideSiteHeader background="#fff">
      <Head pageContext={{ ...pageContext, canonicalPath: '/' }} />
      <LearnLanguage l2Code={pageContext.l2Code || LanguageCode.en} />
    </Layout>
  )
}

export default LearnLanguagePage
