import React from 'react'

import './assets/styles.css'
import bloomberg from './assets/images/bloomberg.svg'
import dieZeit from './assets/images/die-zeit.svg'
import gruenderszene from './assets/images/gruenderszene.svg'
import t3n from './assets/images/t3n.svg'
import wirtschaftsWoche from './assets/images/wirtschafts-woche.svg'

const LearnLanguagePress: React.FC = () => {
  return (
    <section className="landing-press container">
      <div className="landing-section-reveal row">
        <div className="col-12 col-tablet-10 offset-tablet-1 px-0">
          <div className="landing-press__items row flex-wrap no-gutters align-items-center justify-content-center justify-content-tablet-wide-between">
            <div className="my-2x ml-4x order-1">
              <img src={dieZeit} loading="lazy" alt="Press" />
            </div>
            <div className="my-2x ml-4x order-2 text-mobile-right">
              <img src={wirtschaftsWoche} loading="lazy" alt="Press" />
            </div>
            <div className="my-2x ml-4x order-3">
              <img src={bloomberg} loading="lazy" alt="Press" />
            </div>
            <div className="order-6 order-tablet-4 my-2x ml-4x">
              <img src={t3n} loading="lazy" alt="Press" />
            </div>
            <img
              src={gruenderszene}
              loading="lazy"
              className="my-2x ml-4x order-5"
              alt="Press"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default LearnLanguagePress
