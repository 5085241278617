import React from 'react'
import { useTranslate } from 'react-polyglot'
import { getLocalizedLanguageLabel } from 'src/lib/localization'
import { LanguageCode } from 'src/types'

import './assets/styles.css'
import coursePreview from './assets/course-preview.svg'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguageCourse: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()
  const items = [
    t('From 1 to 31 <strong>online private lessons</strong> per month'),
    t('Access to all <strong>interactive</strong> learning materials'),
    t(
      'Personalized <strong>learning plan</strong> and <strong>progress report</strong>'
    ),
    t('<strong>Certificate</strong> for each completed level'),
    t('Personal <strong>tutor</strong>'),
    t('Access to our <strong>mobile app</strong> for learning on the go'),
    t(
      '<strong>Exclusive</strong> access to the Chatterbug language learning community'
    ),
  ]

  return (
    <section className="container mt-8x mt-laptop-13x mb-8x mb-laptop-13x">
      <div className="row no-gutters flex-column flex-laptop-row align-items-center">
        <div className="landing-section-reveal landing-course__col col-12 col-tablet-8 col-tablet-wide-6 col-laptop-5 offset-laptop-7 order-3 order-laptop-1 mb-5x mb-laptop-4x mt-7x mt-laptop-0">
          <h2 className="landing-h2 text-center text-laptop-left">
            <p
              dangerouslySetInnerHTML={{
                __html: t('Your <strong>%{l2Name} course</strong> includes', {
                  l2Name: getLocalizedLanguageLabel(l2Code, t),
                }),
              }}
            />
          </h2>
        </div>
        <div className="landing-section-reveal landing-course__col col-12 col-tablet-10 col-tablet-wide-8 col-laptop-5 offset-laptop-1 order-2">
          <img
            src={coursePreview}
            loading="lazy"
            alt="Chatterbug Course"
            width="100%"
          />
        </div>
        <div className="landing-section-reveal landing-course__col col-12 col-tablet-8 col-tablet-wide-6 col-laptop-5 offset-laptop-1 order-4">
          <ul className="list-unstyled">
            {items.map((item, index) => (
              <li
                key={`course-item-${index}`}
                className={`landing-text landing-course__item ${
                  index > 0 ? 'mt-2x mt-tablet-1x' : ''
                }`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default LearnLanguageCourse
