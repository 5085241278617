import React from 'react'
import { useTranslate } from 'react-polyglot'
import { useLessonsPricingPlans } from 'src/data/pricing/plans'

import './assets/styles.css'
import visa from './assets/images/visa.svg'
import sepa from './assets/images/sepa.svg'
import masterCard from './assets/images/master-card.svg'
import americanExpress from './assets/images/american-express.svg'

const LearnLanguagePricing: React.FC = () => {
  const t = useTranslate()
  const plans = useLessonsPricingPlans()

  return (
    <section className="landing-pricing mt-9x">
      <div className="landing-pricing__content pb-10x pb-tablet-13x">
        <div className="container pt-8x">
          <h2 className="landing-section-reveal landing-h2 mb-4x text-center">
            {t('Our Pricing')}
          </h2>
          <div className="landing-section-reveal my-2x landing-text">
            <div className="sc-bdVaJa sc-htoDjs ixFinG">
              {plans.map((plan) => (
                <div
                  key={`pricing-plan-${plan.name}`}
                  className="sc-bdVaJa landing-pricing__package sc-htoDjs ixFinG"
                >
                  <div className="sc-bdVaJa landing-pricing__package-name landing-h2 sc-htoDjs ixFinG">
                    {plan.label}
                  </div>
                  <div className="sc-bdVaJa landing-pricing__package-current sc-htoDjs ixFinG">
                    <div className="sc-bdVaJa sc-htoDjs ixFinG">
                      <strong className="sc-bdVaJa landing-pricing__package-value sc-htoDjs ixFinG">
                        {`€${plan.price}`}
                      </strong>
                      {t('per month')}
                    </div>
                    <div className="sc-bdVaJa landing-pricing__package-includes sc-htoDjs ixFinG">
                      <strong className="sc-bdVaJa landing-pricing__package-value sc-htoDjs ixFinG">
                        {plan.numberOfLessons}
                      </strong>
                      {plan.numberOfLessons === 1
                        ? 'Live Lesson'
                        : 'Live Lessons'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="landing-section-reveal">
            <div className="landing-pricing__accepts row justify-content-center flex-wrap pt-3x">
              <img
                src={visa}
                loading="lazy"
                height="31"
                className="mx-1x mt-1x"
                alt="VISA"
              />
              <img
                src={sepa}
                loading="lazy"
                height="31"
                className="mx-1x mt-1x"
                alt="SEPA"
              />
              <img
                src={masterCard}
                loading="lazy"
                height="31"
                className="mx-1x mt-1x"
                alt="Master Card"
              />
              <img
                src={americanExpress}
                loading="lazy"
                height="31"
                className="mx-1x mt-1x"
                alt="American Express"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LearnLanguagePricing
