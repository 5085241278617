import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Flex, Box, Grid } from '@chatterbug/aaron'
import ButtonLogin from 'src/sections/ButtonLogin/ButtonLogin'
import ButtonSignUp from 'src/sections/ButtonSignUp/ButtonSignUp'
import Logo from 'src/components/Logo/Logo'
import LocalizedLink from 'src/components/LocalizedLink/LocalizedLink'
import { LanguageCode } from 'src/types'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguageHeader: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()

  return (
    <Flex
      as="header"
      minHeight={68}
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor="gray60"
    >
      <Flex alignItems="center">
        <Box mt="05x">
          <LocalizedLink to="/">
            <Logo />
          </LocalizedLink>
        </Box>
      </Flex>
      <Grid
        columns="repeat(2, auto)"
        gap="2x"
        alignItems="center"
        display={{ _: 'none', tablet: 'grid' }}
      >
        <ButtonLogin />
        <ButtonSignUp label={t('Free trial')} l2Code={l2Code} size="normal" />
      </Grid>
    </Flex>
  )
}

export default LearnLanguageHeader
