/* Migrated from https://github.com/chatterbugapp/chatterblog/blob/master/src/_includes/landing/de-en.html */
import React, { useState } from 'react'
import { LanguageCode, LanguageDropDownItem } from 'src/types'

import './assets/styles.css'
import LearnLanguageHero from './subcomponents/LearnLanguageHero/LearnLanguageHero'
import LearnLanguageFeatures from './subcomponents/LearnLanguageFeatures/LearnLanguageFeatures'
import LearnLanguagePricing from './subcomponents/LearnLanguagePricing/LearnLanguagePricing'
import LearnLanguageTrial from './subcomponents/LearnLanguageTrial/LearnLanguageTrial'
import LearnLanguageCourse from './subcomponents/LearnLanguageCourse/LearnLanguageCourse'
import LearnLanguagePress from './subcomponents/LearnLanguagePress/LearnLanguagePress'
import LearnLanguageBottom from './subcomponents/LearnLanguageBottom/LearnLanguageBottom'
import SectionReveal from './subcomponents/SectionReveal/SectionReveal'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguage: React.FC<Props> = ({ l2Code }) => {
  const [selectedL2Code, setSelectedL2Code] = useState<LanguageCode>(l2Code)

  const handleSelectedL2Change = (item: LanguageDropDownItem | null) => {
    if (item) {
      setSelectedL2Code(item.value)
    }
  }

  return (
    <>
      <LearnLanguageHero
        selectedL2Code={selectedL2Code}
        onSelectedL2Change={handleSelectedL2Change}
      />
      <LearnLanguageFeatures l2Code={selectedL2Code} />
      <LearnLanguagePricing />
      <LearnLanguageTrial l2Code={selectedL2Code} />
      <LearnLanguageCourse l2Code={selectedL2Code} />
      <LearnLanguagePress />
      <LearnLanguageBottom l2Code={selectedL2Code} />
      <SectionReveal />
    </>
  )
}

export default LearnLanguage
