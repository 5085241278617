import React from 'react'
import { useTranslate } from 'react-polyglot'
import ButtonSignUp from 'src/sections/ButtonSignUp/ButtonSignUp'
import { getLocalizedLanguageLabel } from 'src/lib/localization'
import { LanguageCode } from 'src/types'

import './assets/styles.css'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguageBottom: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()

  return (
    <div className="landing-bottom landing-bottom--v2">
      <div className="landing-bottom__background landing-bottom__background--v2" />
      <div className="landing-bottom__wave landing-bottom__wave--v2" />
      <div className="landing-bottom__content landing-bottom__content--v2">
        <div className="container">
          <div className="row">
            <div className="landing-bottom__message landing-bottom__message--v2 px-0 col-12 col-tablet-5 col-tablet-wide-4 col-laptop-3 offset-tablet-1 text-center text-tablet-left">
              <div className="landing-h1">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('Speak <strong>%{l2Name}</strong> now', {
                      l2Name: getLocalizedLanguageLabel(l2Code, t),
                    }),
                  }}
                />
              </div>
              <div className="mt-4x">
                <ButtonSignUp l2Code={l2Code} size="large" />
              </div>
            </div>
          </div>
          <div className="landing-bottom__foreground landing-bottom__foreground--v2">
            <div className="landing-bottom__foreground-content landing-bottom__foreground-content--v2" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnLanguageBottom
