import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'
import { getLocalizedLanguageLabel } from 'src/lib/localization'
import { LanguageCode } from 'src/types'

import Feature1Icon from './assets/feature-1.inline.svg'
import Feature2Icon from './assets/feature-2.inline.svg'
import Feature3Icon from './assets/feature-3.inline.svg'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguageFeatures: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()

  const features = [
    {
      title: t('Online <strong>lessons</strong>'),
      description: t(
        'In the 45-minute <strong>Live Lessons</strong> you will learn to speak %{l2Name} with the help of qualified tutors. All the exercises contained therein were developed by our <strong>language didactics</strong> and put together for you.',
        {
          l2Name: getLocalizedLanguageLabel(l2Code, t),
        }
      ),
      icon: <Feature1Icon />,
    },
    {
      title: t('Flexible <strong>learning</strong>'),
      description: t(
        'You also get <strong>unlimited</strong> access to our interactive multimedia learning material, with which you can improve your reading, writing and listening skills at <strong>your own pace</strong>.'
      ),
      icon: <Feature2Icon />,
    },
    {
      title: t('Certified <strong>results</strong>'),
      description: t(
        'For every completed level you will receive a <strong>certificate</strong>. This confirms your linguistic knowledge according to the standard of the <strong>Common European Framework of Reference for Languages</strong> (GER).'
      ),
      icon: <Feature3Icon />,
    },
  ]

  return (
    <section className="container">
      <ul className="row no-gutters justify-content-center list-unstyled">
        {features.map((feature, index) => (
          <li
            key={`feature-${index}`}
            className={`col-12 col-tablet-4 col-tablet-wide-3 px-2x text-center mt-9x mt-tablet-0 ${
              index !== 1 ? 'mt-tablet-2x' : ''
            }`}
          >
            <Box width={140} height={140} mx="auto" aria-hidden="true">
              {feature.icon}
            </Box>
            <div className="landing-section-reveal landing-h2 mt-2x mt-tablet-wide-8x row no-gutters justify-content-center">
              <div>
                <Box display="table-caption">
                  <p dangerouslySetInnerHTML={{ __html: feature.title }} />
                </Box>
              </div>
            </div>
            <div className="landing-section-reveal landing-text mt-4x">
              <p dangerouslySetInnerHTML={{ __html: feature.description }} />
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default LearnLanguageFeatures
