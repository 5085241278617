import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Grid } from '@chatterbug/aaron'
import PageContainer from 'src/components/PageContainer/PageContainer'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import SignUpActions from 'src/sections/SignUpActions/SignUpActions'
import LearnLanguageHeader from 'src/sections/LearnLanguage/subcomponents/LearnLanguageHeader/LearnLanguageHeader'
import { getLocalizedLanguageLabel } from 'src/lib/localization'
import { LanguageDropDownItem, LanguageCode } from 'src/types'

import wave from './assets/wave.svg'
// `jpg` has background color but it's much smaller than `png`
import previewForEn from './assets/preview-for-en@2x.jpg'
import previewForDe from './assets/preview-for-de@2x.jpg'
import previewForEs from './assets/preview-for-es@2x.jpg'
import previewForFr from './assets/preview-for-fr@2x.jpg'

const WAVE_SIZE = {
  width: 1440,
  height: 80,
}

const getPreviewImage = (l2Code: LanguageCode): string => {
  switch (l2Code) {
    case LanguageCode.en:
      return previewForEn
    case LanguageCode.de:
      return previewForDe
    case LanguageCode.es:
      return previewForEs
    case LanguageCode.fr:
      return previewForFr
    default:
      break
  }

  return previewForEn
}

export type Props = {
  selectedL2Code: LanguageCode
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

const LearnLanguageHero: React.FC<Props> = ({
  selectedL2Code,
  onSelectedL2Change,
}) => {
  const t = useTranslate()

  return (
    <Box as="section" bg="#fff1cc" mb={{ _: 0, tablet: '3x' }}>
      <PageContainer>
        <LearnLanguageHeader l2Code={selectedL2Code} />
        <Grid
          columns={{
            _: '1fr',
            tablet: '1fr 1fr',
            laptop: '5fr 7fr',
          }}
          gridGap={{ _: '2x', laptop: '3x' }}
          mt={{
            _: '4x',
            tablet: 72,
          }}
          pb={72}
        >
          <Box
            order={{
              _: 2,
              tablet: 1,
            }}
            mt={{
              _: 0,
              tablet: '4x',
            }}
          >
            <Grid
              colums={1}
              gap="3x"
              textAlign={{ _: 'center', tablet: 'left' }}
            >
              <Box
                as="h1"
                variant="text.h1"
                lineHeight={1.2}
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Learn <strong>%{l2Name}</strong><br>with Chatterbug',
                    {
                      l2Name: getLocalizedLanguageLabel(selectedL2Code, t),
                    }
                  ),
                }}
              />
              <Box
                variant="text.paragraph"
                lineHeight={1.6}
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Become fluent in %{l2Name} in no time with our<br/>one-on-one <strong>Live Lessons</strong>!',
                    {
                      l2Name: getLocalizedLanguageLabel(selectedL2Code, t),
                    }
                  ),
                }}
              />
              <SignUpActions
                ctaLabel={t('Start free trial')}
                selectedL2Code={selectedL2Code}
                onSelectedL2Change={onSelectedL2Change}
              />
              <Box
                variant="text.paragraph"
                lineHeight={1.6}
                dangerouslySetInnerHTML={{
                  __html: t(
                    '<strong>%{l2Name}</strong> courses from <strong>A1</strong> to <strong>C2</strong>',
                    {
                      l2Name: getLocalizedLanguageLabel(selectedL2Code, t),
                    }
                  ),
                }}
              />
            </Grid>
          </Box>
          <Box
            order={{
              _: 1,
              tablet: 2,
            }}
          >
            <Box
              sx={{
                paddingTop: `${(372 * 100) / 662}%`,
                background: `url(${getPreviewImage(selectedL2Code)}) no-repeat`,
                backgroundSize: '100%',
                transition: 'background 0.3s ease',
              }}
            />
          </Box>
        </Grid>
      </PageContainer>
      <FullWidthBackground
        image={wave}
        width={WAVE_SIZE.width}
        height={WAVE_SIZE.height}
        sx={{
          marginBottom: -10, // The negative margin is to hide the bottom of the gradient background
        }}
      />
    </Box>
  )
}

export default LearnLanguageHero
