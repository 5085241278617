import React from 'react'
import { useTranslate } from 'react-polyglot'
import ButtonSignUp from 'src/sections/ButtonSignUp/ButtonSignUp'
import { LanguageCode } from 'src/types'

import trial from './assets/trial.svg'

export type Props = {
  l2Code: LanguageCode
}

const LearnLanguageTrial: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()

  return (
    <section className="py-3x border-bottom border-top border-jaguar_light">
      <div className="container">
        <div className="row">
          <div className="col-12 col-tablet-wide-8 offset-tablet-wide-2 col-laptop-8 offset-laptop-2">
            <div className="row no-gutters flex-column flex-tablet-row align-items-center justify-content-center justify-content-tablet-wide-between">
              <div className="row flex-column flex-tablet-row align-items-center">
                <img src={trial} loading="lazy" alt="Trial" width="49" />
                <div className="ml-tablet-2x landing-text my-2x my-tablet-0 text-center">
                  <strong>
                    {t('14-day trial subscription including one Live Lesson')}
                  </strong>
                </div>
              </div>
              <div className="pl-tablet-3x">
                <ButtonSignUp l2Code={l2Code} size="large" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LearnLanguageTrial
