import React, { PropsWithChildren } from 'react'
import { withTheme } from 'styled-components'
import { Box } from '@chatterbug/aaron'

export type Props = PropsWithChildren<{
  image: string
  width: number | null
  height: number | null
  preserveScale?: boolean
  sx?: Record<string, unknown>
  display?: string | Record<string, unknown>
  theme?: {
    breakpoints: {
      desktop: string
    }
  }
}>

const FullWidthBackground: React.FC<Props> = ({
  image,
  width,
  height,
  theme,
  preserveScale,
  sx,
  display,
  children,
}) => {
  const backgroundHeight = children ? '100%' : `${height}px`
  return (
    <Box
      // @ts-ignore
      display={display}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        ...(preserveScale
          ? {
              paddingTop: height,
              backgroundSize: `${width}px ${height}px`,
            }
          : {
              paddingTop: children ? 0 : height,
              backgroundSize: `${width}px ${backgroundHeight}`,
              [`@media screen and (min-width: ${theme?.breakpoints.desktop})`]: {
                backgroundSize: `100% ${backgroundHeight}`,
              },
            }),
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default withTheme(FullWidthBackground)
